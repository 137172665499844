<template>
  <div
    ref="root"
    class="atomic-timer"
    :class="`atomic-timer--${props.size || 'sm'}`"
    :style="{ width: `${timerWidth}px` }"
  >
    <span v-show="Number(days) > 0" class="atomic-timer__days">
      {{ days }}{{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'atomic.timerDaysLabel') }}
    </span>

    <span class="atomic-timer__digit">{{ format(hours) }}</span>
    <span class="atomic-timer__dots">:</span>
    <span class="atomic-timer__digit">{{ format(minutes) }}</span>
    <span class="atomic-timer__dots">:</span>
    <span class="atomic-timer__digit">{{ format(seconds) }}</span>
  </div>
</template>

<script setup lang="ts">
  const props = withDefaults(
    defineProps<{
      expiredAt: string | number;
      hideLabels?: boolean;
      size?: 'sm' | 'md';
      type?: 'inter' | 'jost';
    }>(),
    {
      size: 'sm',
      type: 'jost',
    }
  );

  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const { days, hours, minutes, seconds, isAlmostDone, startTimer } = useTimer();
  const { getContent } = useProjectMethods();
  const format = (value: string | number): number | string => (Number(value) < 10 ? `0${value}` : value);
  const emit = defineEmits(['time-up']);

  const timerWidth = ref(0);
  const root = ref<HTMLElement | null>(null);
  const setTimerWidth = (): void => {
    const timerScrollWidth = root.value?.scrollWidth;
    timerWidth.value = timerScrollWidth ? timerScrollWidth + 3 : 0;
  };

  watch(isAlmostDone, value => {
    if (value) emit('time-up');
  });

  const mounted = ref(false);
  watch(days, async () => {
    if (!mounted.value) return;
    await nextTick();
    setTimerWidth();
  });

  onMounted(async () => {
    startTimer(props.expiredAt);
    await nextTick();
    setTimerWidth();
    mounted.value = true;
  });
</script>

<style src="~/assets/styles/components/atomic/timer.scss" lang="scss" />
